<script>
import api from "@/api";

export default {
  name: "Photos",
  data: function () {
    return {
      photos: [],
      images: [],
      formData: null,
    };
  },
  methods: {
    removePhoto(id) {
      const url = "/SpacePhoto/" + id;
      api.Manager.axios.delete(url).then(() => {
        this.getPhotos();
      });
    },
    getPhotos() {
      const url = "/api/SpacePhotos/" + this.slug;
      api.Util.axios.get(url).then((response) => {
        const data = response.data;
        var a = data;
        if (data.results) {
          a = data.results;
        }
        this.photos = a;
      });
    },
    addImage(event) {
      var p = this;
      p.images = [];
      var fileList = event.target.files;
      this.formData = new FormData();
      for (var i = 0; i < fileList.length; i++) {
        this.formData.append("image" + i, fileList[i], fileList[i].name);
        var reader = new FileReader();
        reader.onload = function (e) {
          p.images.push(e.target.result);
        };
        reader.readAsDataURL(fileList[i]);
      }
    },
    uploadImages() {
      var p = this;
      const url = "/SpacePhotos/" + p.slug;
      api.Manager.axios.post(url, this.formData).then(() => {
        alert("Uploaded");
        p.images = [];
        p.formData = null;
        p.getPhotos();
      });
    },
  },
  computed: {
    slug() {
      return this.$route.params.slug;
    },
  },
  created() {
    this.getPhotos();
  },
};
</script>

<template>
  <div id="Photos" class="container-fluid">
    <div class="row" id="existent_photos">
      <div class="col-12" v-if="photos.length">
        <div class="row">
          <div
            class="col-3 text-center"
            v-for="photo in photos"
            :key="photo.id"
          >
            <img
              class="img-thumbnail"
              :src="'/media/' + photo.picture.pathname"
              alt=""
            />
            <div class="overlay">
              <button
                class="btn btn-danger text-center"
                @click="removePhoto(photo.id)"
              >
                <i class="fa fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="text-center col-12 no_photos">No Photos</div>
    </div>

    <div class="row" id="add_photos">
      <form
        class="col-12"
        @submit.prevent="uploadImages"
        method="POST"
        action="/"
        enctype="multipart/form-data"
      >
        <h5 class="text-center">Add New Images</h5>
        <div class="images col-12">
          <div class="row">
            <div
              class="u_image col-6 col-md-3"
              v-for="image in images"
              :key="image.id"
            >
              <img class="img-thumbnail" :src="image" alt="" />
            </div>
          </div>
        </div>

        <div class="justify-content-center form-group">
          <input
            type="file"
            name="pic_file"
            value="Add file."
            @change="addImage($event)"
            multiple
          />
        </div>
        <div class="form-group justify-content-center text-center">
          <input class="btn btn-danger" type="submit" value="Upload" />
        </div>
      </form>
    </div>
  </div>
</template>

<style lang="scss">
#Photos {
  background: white;
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: rgb(227, 228, 231) 0px 2px 0px 0px;
  border: 1px solid rgb(241, 241, 241);
  .no_photos {
    border: 1px solid #ccc;
    padding: 5px 10px;
    margin-top: 5px;
  }
  #existent_photos {
    img {
      height: 200px;
    }
    button {
      margin-top: 3px;
    }
  }
  #add_photos {
    border-top: 1px solid #ccc;
    padding-top: 15px;
    margin-top: 15px;
  }
}
</style>
