<script>
import { MyComponent } from "@/components/MyComponent.js";
import api from "@/api";

export default {
  name: "Photos",
  mixins: [MyComponent],
  props: ["space"],
  data: function () {
    return {
      photos: [],
    };
  },
  computed: {
    slug() {
      return this.space.slug;
    },
  },
  methods: {
    getPic: function (pic) {
      var spaceCompany = pic;
      if (!pic) {
        return "";
      }
      if (pic.pathname) {
        return "/static/tizi/images/" + pic.pathname;
      } else if (pic.data) {
        return pic.data;
      } else if (spaceCompany.logo) {
        return this.getPic(spaceCompany.logo);
      }
      return "";
    },
    getPhotos() {
      var url = "/SpacePhotos/" + this.slug;
      api.Util.axios.get(url).then((response) => {
        const data = response.data;
        var a = data;
        if (data.results) {
          a = data.results;
        }
        this.photos = a;
      });
    },
  },
  watch: {
    slug() {
      this.getPhotos();
    },
  },
  created() {
    this.getPhotos();
  },
};
</script>

<template>
  <div id="PhotoParent" class="col-12 text-center">
    <div id="Photos" class="row justify-content-center">
      <div
        class="col-12 col-md-offset-2 col-md-8"
        v-if="photos && photos.length == 0"
      >
        <div class="row nophotos justify-content-center text-center">
          <div class="col-md-offset-3 col-md-6 col-10">No Photos</div>
        </div>
      </div>

      <div id="holder" v-else>
        <template v-for="photo in photos">
          <div class="img-col" :key="photo">
            <img :src="'/media/' + photo.picture.pathname" alt="" />
            <div class="box_data"></div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
#Photos {
  margin-top: 20px;
  #holder {
    width: 900px;
    position: relative;
    .box_data {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      color: #fff;
    }
    .img-col {
      display: inline-block;
      overflow: hidden;
      float: left;
      width: 33.3%;
      position: relative;
      img {
        width: 100%;
      }
      &:hover {
        img {
          transform: scale(1.1);
          transition: transform 0.5s ease;
        }
      }
      &:nth-child(even) {
        /*img{
          height: 330px;
          width: 300px;
        }*/
        .box_data {
          background: rgba(0, 44, 255, 0.15);
        }
      }
      &:nth-child(odd) {
        /*img{
                height: 270px;
                width: 300px;
              }*/
        .box_data {
          background: rgba(0, 0, 0, 0.15);
        }
      }
      &:nth-child(3n) {
        /*clear: right;*/
      }
    }
  }
}
</style>
